#icon {
  width: 30px;
  height: 35px;
  position: relative;
  margin: 12px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#icon.open.dark span {
  background: black;
}

#icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 50%;
  background: white;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#icon span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#icon span:nth-child(odd) {
  left:0px;
  border-radius: 9px 0 0 9px;
}

#icon span:nth-child(1), #icon span:nth-child(2) {
  top: 0px;
}

#icon span:nth-child(3), #icon span:nth-child(4) {
  top: 10px;
}

#icon span:nth-child(5), #icon span:nth-child(6) {
  top: 20px;
}

#icon.open span:nth-child(1),#icon.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#icon.open span:nth-child(2),#icon.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#icon.open span:nth-child(1) {
  left: 3px;
  top: 9px;
}

#icon.open span:nth-child(2) {
  left: calc(50% - 2px);
  top: 9px;
}

#icon.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#icon.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#icon.open span:nth-child(5) {
  left: 3px;
  top: 19px;
}

#icon.open span:nth-child(6) {
  left: calc(50% - 2px);
  top: 19px;
}
