.navbar {
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0));
}

.nav-link {
    color: black;
    font-size: 60px;
    text-shadow: 1px 2px 4px rgb(0 0 0 / 40%);

}

.nav-link:hover {
    color: #2bffeb;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

/* For the offcanvas menu */
.offcanvas-start {
    width: 100%;
}

.offcanvas-header {
    padding-top: 6px;
}


.open-menu {
    background-color: rgb(20,20,20);
    width: 100%;
}

.navbar-link {
    color: white;
    font-size: 20px;
    font-weight: bold;
    background-color: rgb(40,40,40);
    width: 100%;
    padding: 12px;
    text-align: center;
    margin-bottom: 12px;
    border-radius: 8px;
    text-decoration: none;
}

.selected {
    background-color: rgb(254, 46, 230);
}

.open-nav {
    width: 100%;
    padding-right: 0;
}

.nav-title {
    font-size: 18px;
    color: rgb(230,230,230);
    font-family: 'ARCO';
}

.social-link {
    color: white;
    font-size: 14px;
    background-color: rgb(30,30,30);
    color: rgb(220,220,220);
    width: 100%;
    padding: 12px;
    text-align: center;
    margin-bottom: 12px;
    border-radius: 8px;
}

.section-header {
    color: rgb(100,100,100);
    font-size: 12px;
    padding-bottom: 5px;
    text-align: center;
}