.title {
    font-family: 'ARCO';
    font-size: 84px;
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
}

.title.mobile-title {
    font-size: 30px;
}

.subtitle {
    font-family: 'ARCO';
    font-size: 30px;
    text-shadow: 0px 0px 13px rgba(0,0,0,0.63);
}

.subtitle.mobile-subtitle {
    font-size: 12px;
}

.home-button, .home-button:active, .home-button:hover, .home-button:focus {
    background-color: rgb(254, 46, 230);
    font-size: 18px;
    font-weight: bold;
    border-width: 0;
}

